import { GOOGLE } from "@withjuly/fabric";
import { ModalBody, ModalHeader, Text } from "@withjuly/solis";
import { useConnectAccount } from "~/utils/hooks/oauth";
import { FcGoogle } from "react-icons/fc";

interface ConnectGoogleContentProps {
	companyName?: string;
	setIsOpen: (isOpen: boolean) => void;
	onConnected?: () => void;
}

export const ConnectGoogleContent: React.FC<ConnectGoogleContentProps> = ({
	companyName,
	onConnected,
}) => {
	const { onConnect } = useConnectAccount(GOOGLE, () => onConnected?.());

	return (
		<>
			<div className="flex flex-col gap-3">
				<ModalHeader>
					<div className="flex w-full items-center justify-between">
						<h1 className="text-h4 font-agrandir">
							{companyName
								? `One more step to pitch ${companyName}`
								: "One more step to pitch these brands"}{" "}
							🤑
						</h1>
					</div>
				</ModalHeader>
				<ModalBody className="flex flex-col gap-4">
					<Text variant="body/lg" className="text-gray-100">
						On July, pitches are sent directly from your email address. This
						makes the brand more likely to respond, since your message is coming
						from a real person.
					</Text>
					<button
						className="bg-white-100 flex h-12 w-full justify-center rounded-lg py-4"
						onClick={onConnect}
					>
						<div className="flex items-center gap-2">
							<FcGoogle />
							<Text variant="bold/lg" className="text-gray-300">
								Sign in with Google
							</Text>
						</div>
					</button>
				</ModalBody>
			</div>
		</>
	);
};
