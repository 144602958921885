import { useRandomGradient } from "~/utils/placeholder";

export const CompanyImage = ({
	post,
	isBackground,
	className,
	alt,
	onClick,
}: {
	post?: {
		mediaUrl: string;
		mediaType: string;
	};
	isBackground?: boolean;
	className?: string;
	alt: string;
	onClick?: () => void;
}) => {
	// If there's no images to load, show a random gradient
	// This is inside a memo to prevent the gradient from changing on every render
	const randomGradient = useRandomGradient();

	if (isBackground) {
		className =
			(className ?? "") +
			" absolute w-full h-full object-cover top-0 left-0 transition-opacity";
	}

	let image;
	if (post?.mediaType === "IMAGE" || post?.mediaType === "CAROUSEL_ALBUM") {
		image = (
			<img
				src={post?.mediaUrl}
				loading="lazy"
				className={className}
				alt={alt}
				onClick={onClick}
			/>
		);
	} else if (post?.mediaType === "VIDEO") {
		image = (
			<video preload="metadata" className={className} onClick={onClick}>
				<source src={post.mediaUrl + "#t=0.5"} type="video/mp4" />
			</video>
		);
	} else {
		image = (
			<div
				className={className}
				style={{ backgroundImage: randomGradient }}
				onClick={onClick}
			/>
		);
	}

	return image;
};
