import { AlsoWorkingWithMatchInfo } from "@withjuly/fabric";
import TextTruncate from "react-text-truncate";

export interface AwwPostProps {
	entry: AlsoWorkingWithMatchInfo;
	onClick: () => void;
}

export const AwwPost: React.FC<AwwPostProps> = ({ entry, onClick }) => {
	const formatFollowerCount = (followerCount: number): string => {
		if (followerCount < 1_000_000) {
			const thousands = Math.round(followerCount / 10) / 100;
			return `${thousands}k`;
		} else {
			const millions = Math.round(followerCount / 100_000) / 10;
			return `${millions}m`;
		}
	};

	return (
		<div
			className="flex w-full cursor-pointer items-center gap-4"
			onClick={onClick}
		>
			<img
				src={entry.imageUrl}
				className="aspect-square h-20 w-20 rounded-lg object-cover"
				loading="lazy"
				alt={`@${entry.username}'s ${entry.platform} post`}
			/>
			<div className="flex h-20 w-full flex-col">
				<div className="flex w-full justify-between text-sm">
					<p className="text-bold-lg">@{entry.username}</p>
					<p className="text-bold-lg text-gray-100">
						{formatFollowerCount(entry.followerCount)}
					</p>
				</div>
				<p
					className="text-bold-sm overflow-hidden text-gray-100"
					style={{
						// 12 pt font size * 1.25 line height * 3 lines
						maxHeight: 12 * 1.25 * 3,
					}}
				>
					<TextTruncate
						line={3}
						element="span"
						truncateText="…"
						text={entry.caption}
					/>
				</p>
			</div>
		</div>
	);
};
