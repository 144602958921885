import { Brand } from "@withjuly/fabric";

interface BrandHeaderProps {
	brand: Brand;
}

export const BrandHeader: React.FC<BrandHeaderProps> = ({ brand }) => {
	return (
		<div className="flex w-full flex-col items-start">
			<div className="flex w-full flex-col items-start justify-between">
				<div className="flex min-h-[1.75rem] items-start gap-2">
					<h2 className="text-h5 font-agrandir max-w-[16ch] truncate">
						{brand.company.name}
					</h2>
				</div>
				<p className="text-gray-100">
					{brand.campaign?.industries
						.split(",")
						.map((industry) => industry.trim())
						.join(", ")}
				</p>
			</div>
		</div>
	);
};
