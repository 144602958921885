import { useMemo } from "react";

const randInRange = (min: number, max: number) => {
	return Math.random() * (max - min) + min;
};

export const useRandomGradient = () => {
	return useMemo(() => {
		const startHue = Math.floor(randInRange(0, 360));
		const startSat = Math.floor(randInRange(50, 100));
		const startLit = Math.floor(randInRange(70, 90));
		const startHsl = `hsl(${startHue}, ${startSat}%, ${startLit}%)`;

		// Generate new hsl color based on previous color, following the advice of
		// the big color wheel in the sky
		const hueType = Math.floor(randInRange(0, 3));
		let endHsl;
		if (hueType === 0) {
			endHsl = `hsl(${(startHue + 180) % 360}, ${startSat}%, ${startLit}%)`;
		} else if (hueType === 1) {
			endHsl = `hsl(${(startHue + 90) % 360}, ${startSat}%, ${startLit}%)`;
		} else {
			endHsl = `hsl(${(startHue + 45) % 360}, ${startSat}%, ${startLit}%)`;
		}

		const deg = Math.floor(randInRange(0, 360));
		return `linear-gradient(${deg}deg, ${startHsl}, ${endHsl})`;
	}, []);
};
