import { Brand } from "@withjuly/fabric";
import { Add, Check, AddToQueue as QueueAdd } from "@withjuly/julycons";
import { Tooltip, TooltipTrigger, TooltipContent } from "@withjuly/solis";
import { useCallback, useState } from "react";
import { usePitchQueue } from "~/utils/context/pitch-queue";
import { useCreator } from "~/utils/context/creator";
import { cx } from "@withjuly/frontend-common";
import { useBilling } from "~/utils/context/billing";
import { useMemo } from "react";
import { UpgradeModal } from "~/components/Payment/UpgradeModal";

interface AddToQueueProps {
	brand: Brand;
}

export const AddToQueue: React.FC<AddToQueueProps> = ({ brand }) => {
	const { addToQueue, removeFromQueue, isInQueue, brands } = usePitchQueue();
	const queued = isInQueue(brand);
	const { creatorProfile } = useCreator();
	const billing = useBilling();
	const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(() => false);

	const disabled = useMemo(() => {
		let totalPlanCredits = 5;
		if (billing?.plan === "trending") {
			totalPlanCredits = 50;
		} else if (billing?.plan === "paid") {
			totalPlanCredits = 500;
		}
		const creditsUsed = Math.max(
			totalPlanCredits - (creatorProfile?.pitchCredits ?? 0),
			0,
		);

		const creditsLeft = totalPlanCredits - creditsUsed;

		return creditsLeft <= brands.length;
	}, [billing?.plan, brands.length, creatorProfile?.pitchCredits]);

	const toggleQueueMembership = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			if (queued) {
				removeFromQueue(brand);
			} else if (!disabled) {
				addToQueue(brand);
			}
		},
		[queued, disabled, removeFromQueue, brand, addToQueue],
	);

	const QUEUE_BUTTONS = {
		remove: {
			label: `${brand.company.name} has been added to your queue so you can pitch them alongside other brands at the same time!`,
			icon: Check,
			aria: "Remove from queue",
		},
		add: {
			label: `Add ${brand.company.name} to your queue so you can pitch multiple brands at once!`,
			icon: QueueAdd,
			aria: "Add to queue",
		},
	};

	return (
		<>
			<div className="flex items-center" onClick={toggleQueueMembership}>
				<Tooltip open={isUpgradeModalOpen ? false : undefined}>
					<TooltipTrigger asChild>
						<button
							className={cx(
								"relative flex h-[42px] w-[42px] items-center justify-center overflow-hidden rounded-full transition-all duration-75 ease-out",
								"text-white-100 bg-gray-500 hover:bg-gray-400 hover:text-gray-500",
								"transition-all duration-150 ease-in-out",
								"focus-visible:ring-4",
								" hover:bg-blue-500",
								disabled &&
									!queued &&
									"cursor-not-allowed bg-gray-500 text-gray-300 hover:bg-gray-500 hover:text-gray-300",
								queued && "bg-blue-500 text-gray-900",
							)}
						>
							<Add
								className={cx(
									"absolute h-full text-center transition-all ease-out",
									queued ? "top-[42px]" : "top-0",
								)}
								aria-label={QUEUE_BUTTONS.add.aria}
							/>
							<Check
								className={cx(
									"absolute h-full text-center transition-all ease-out",
									queued ? "top-0" : "-top-[42px]",
								)}
								aria-label={QUEUE_BUTTONS.add.aria}
							/>
						</button>
					</TooltipTrigger>
					<TooltipContent side="bottom">
						{disabled && !queued ? (
							<p className="max-w-xs text-center">
								You need more pitch credits to queue another brand. Please
								upgrade your account to{" "}
								<span
									className="text-blue-500 underline"
									role="button"
									onClick={() => setIsUpgradeModalOpen(() => true)}
								>
									here
								</span>
								.
							</p>
						) : (
							<p className="max-w-xs text-center">
								{queued ? "Remove from queue" : "Add to queue"}
							</p>
						)}
					</TooltipContent>
				</Tooltip>
				<UpgradeModal
					isOpen={isUpgradeModalOpen}
					setIsOpen={setIsUpgradeModalOpen}
				/>
			</div>
		</>
	);
};
