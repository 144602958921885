import { Brand } from "@withjuly/fabric";
import { useState } from "react";
import { Button, Drawer, Modal, modalSizes, useToast } from "@withjuly/solis";
import { PostPreview, PostPreviewModal } from "./PostPreviewModal";
import { MatchDrawerHeader } from "./MatchDrawer/MatchDrawerHeader";
import { MatchDrawerFeed } from "./MatchDrawer/MatchDrawerFeed";
import { MatchDrawerAww } from "./MatchDrawer/MatchDrawerAww";
import { Delete, Pitch } from "@withjuly/julycons";
import { trpc } from "~/components/Utility/trpc";

interface MatchDrawerProps {
	brand: Brand;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	onPitch: () => void;
	isBrandBook?: boolean;
}

export const MatchDrawer: React.FC<MatchDrawerProps> = ({
	brand,
	isOpen,
	setIsOpen,
	onPitch,
	isBrandBook,
}) => {
	const [currentPost, setCurrentPost] = useState<PostPreview>();
	const [isPostModalOpen, setIsPostModalOpen] = useState(false);
	const { toast } = useToast();
	const utils = trpc.useContext();
	const deleteMatches = trpc.match.deleteMany.useMutation({
		onSuccess: () => {
			toast({
				title: "Removed match",
				description: "You have removed this match from your list.",
				status: "neutral",
			});
			utils.match.paginated.invalidate();
			setIsOpen(false);
		},
		onMutate: () => {
			const previousBrandMatches = utils.match.paginated.getData();

			if (previousBrandMatches) {
				const newMatches = previousBrandMatches.items.filter(
					(b) => b.match?.uuid !== brand.match?.uuid,
				);
				utils.match.paginated.setData({ limit: 5 }, () => ({
					...previousBrandMatches,
					items: newMatches,
				}));

				setIsOpen(false);
			}
		},
	});

	return (
		<Drawer
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			content={
				<>
					<div className="flex h-full flex-col overflow-y-auto">
						<MatchDrawerHeader brand={brand} setIsOpen={setIsOpen} />
						<MatchDrawerFeed brand={brand} />
						<MatchDrawerAww
							brand={brand}
							setCurrentPost={setCurrentPost}
							setIsPostModalOpen={setIsPostModalOpen}
						/>
					</div>
					<div className="flex gap-3 border-t border-gray-300 px-8 py-6">
						{!isBrandBook ? (
							<Button
								full
								size="large"
								variant="outline"
								className="max-w-[42px]"
								onClick={() => {
									if (brand.match || brand.campaign) {
										const matchPayload = {
											type: brand.match ? "match" : "campaign",
											data:
												(brand.match
													? brand.match.uuid
													: brand.campaign?.uuid) ?? "",
										} as const;
										deleteMatches.mutate({ uuids: [matchPayload] });
									}
								}}
							>
								<Delete />
							</Button>
						) : null}
						<Button full size="large" variant="primary" onClick={onPitch}>
							<div className="flex w-full items-center justify-center gap-2">
								Pitch <Pitch />
							</div>
						</Button>
					</div>
					{currentPost && (
						<Modal
							size={modalSizes[480]}
							isOpen={isPostModalOpen}
							setIsOpen={setIsPostModalOpen}
							content={
								<PostPreviewModal
									post={currentPost}
									onClose={() => setIsPostModalOpen(false)}
								/>
							}
						/>
					)}
				</>
			}
		/>
	);
};
