import { Brand } from "@withjuly/fabric";

export const getLastPostedString = (brand: Brand): string | undefined => {
	const mostRecentPost = brand.company.posts.sort(
		(a, b) =>
			new Date(b.postedAt ?? "").getTime() -
			new Date(a.postedAt ?? "").getTime(),
	)[0];
	if (mostRecentPost && mostRecentPost.postedAt) {
		const lastActive = new Date(mostRecentPost.postedAt);
		const monthsAgo =
			new Date().getMonth() -
			lastActive.getMonth() +
			12 * (new Date().getFullYear() - lastActive.getFullYear());

		if (monthsAgo <= 0) {
			return "less than a month ago";
		} else {
			return `${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
		}
	}
};

export const openCompanySite = (site: string | undefined) => {
	if (!site) return;
	window.open(site, "_blank");
};
