import {
	Brand,
	DefaultTemplate,
	formatOxfordCompanyList,
} from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { ArrowLeft, Pitch } from "@withjuly/julycons";
import {
	Button,
	ModalBody,
	ModalDescription,
	ModalHeader,
	ModalTitle,
	Text,
	useToast,
} from "@withjuly/solis";
import posthog from "posthog-js";
import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { z } from "zod";
import { RichTextInput } from "~/components/Input";
import { RichSubjectInput } from "~/components/Input/RichText/RichSubjectInput";
import { useBodyEditor, useSubjectEditor } from "~/components/Templates/utils";
import { trpc } from "~/components/Utility/trpc";
import { useApiErrorHandler } from "~/utils/api";
import { AttachedFile } from "~/utils/context/rich-text";
import { applyApiValidationErrors } from "~/utils/form";
import { useZodForm } from "~/utils/hooks/zod-form";

const PitchModalInputSchema = z.object({
	subject: z.string().min(1, "Subject is required"),
	body: z.string().min(1, "Body is required"),
	attachments: z.array(z.string().uuid()),
	useOnPlatformInbounds: z.boolean(),
	templateUuid: z.string(),
});
export type PitchModalInput = z.infer<typeof PitchModalInputSchema>;

interface EditPitchPaneProps {
	setIsOpen: (isOpen: boolean) => void;
	brands: Brand[];
	template: DefaultTemplate;
	clearDefaultTemplate: () => void;
	onBoardStep?: { step: string; uuid: string } | undefined;
	setOnBoardStep?: (step: { step: string; uuid: string }) => void;
	isBrandWithBudget?: boolean;
	isBrandBook?: boolean;
}

export const EditPitchPane: React.FC<EditPitchPaneProps> = ({
	setIsOpen,
	brands,
	template,
	clearDefaultTemplate,
	onBoardStep,
	setOnBoardStep,
	isBrandWithBudget = false,
	isBrandBook = false,
}) => {
	const [attachments, setAttachments] = useState<AttachedFile[]>(
		template.attachments.map((a) => ({ name: a.fileName, uuid: a.uuid })),
	);
	const { handleApiError } = useApiErrorHandler();
	const utils = trpc.useContext();
	const sendPitch = trpc.dealFlow.pitch.useMutation({
		onSuccess: (_, variables) => {
			if (!variables.isTestPitch) {
				utils.user.invalidate();
				utils.match.paginated.invalidate();
				utils.match.wishlist.invalidate();
				utils.dealFlow.getQueuedPitches.invalidate();
				utils.match.queueable.invalidate();
			}
		},
	});

	const { toast } = useToast();

	const form = useZodForm({
		schema: PitchModalInputSchema,
		values: {
			subject: template.subject ?? "",
			body: template.body ?? "",
			attachments: [],
			useOnPlatformInbounds: false,
			templateUuid: "",
		},
	});

	const subjectEditor = useSubjectEditor(template.subject, {
		onUpdate: (value) => {
			form.setValue("subject", value);
		},
	});
	const bodyEditor = useBodyEditor(template.body, {
		onUpdate: (value) => {
			form.setValue("body", value);
		},
	});

	const onPitch = async (data: PitchModalInput, isTestPitch: boolean) => {
		const pitchData = {
			// We are filtering out any non-string entries so we can confidently type cast
			campaignUuids: brands
				.filter((b) => typeof b.campaign?.uuid === "string")
				.map((b) => b.campaign?.uuid) as string[],
			subject: subjectEditor?.getText() ?? "",
			htmlContent: bodyEditor?.getHTML() ?? "",
			textContent: bodyEditor?.getText() ?? "",
			attachmentUuids: attachments.map((a) => a.uuid),
			useOnPlatformInbounds: data.useOnPlatformInbounds,
		};

		if (
			onBoardStep &&
			setOnBoardStep &&
			brands.find((brand) => brand.match?.uuid === onBoardStep.uuid)
		) {
			setOnBoardStep({ ...onBoardStep, step: "dealflow" });
		}

		sendPitch.mutate(
			{ ...pitchData, isTestPitch },
			{
				onSuccess: () => {
					toast({
						title: "Email Sent",
						status: "success",
						description: `We've sent your message to ${formatOxfordCompanyList(
							brands.map((b) => b.company?.name),
						)}!`,
					});

					posthog.capture("creator pitch", {
						brandCount: pitchData.campaignUuids.length,
						attachmentCount: pitchData.attachmentUuids.length,
						useOnPlatformInbounds: pitchData.useOnPlatformInbounds,
						isBrandBook,
					});
					setIsOpen(false);
				},
				onError: (error) => {
					applyApiValidationErrors(error, form.setError);
					handleApiError(error);
				},
			},
		);
	};

	const values = form.watch();
	const canSend = useMemo(() => {
		return (bodyEditor?.getText().length ?? 0) > 0 && values.subject.length > 0;
	}, [values, bodyEditor]);

	if (!bodyEditor) return null;

	return (
		<>
			<ModalHeader>
				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-3">
						<ModalTitle>
							<span style={{ fontFamily: "system-ui" }}>{template.emoji}</span>{" "}
							{template.name ?? ""}
						</ModalTitle>
					</div>
					<ModalDescription asChild>
						<Text className="text-gray-100">
							This pitch will be sent to{" "}
							{formatOxfordCompanyList(
								brands.map((b) => {
									if (
										b.company.url &&
										b.company.url === "https://www.withjuly.com"
									) {
										return "your email";
									}

									return b.company.name;
								}),
							)}
							.
						</Text>
					</ModalDescription>
				</div>
			</ModalHeader>

			<ModalBody>
				<div className="flex flex-col gap-6">
					<FormProvider {...form}>
						<form
							id="pitch-form"
							onSubmit={form.handleSubmit((data) => onPitch(data, false))}
						>
							<div className="flex flex-col gap-3">
								<RichSubjectInput editor={subjectEditor} />

								<RichTextInput
									name="body"
									editor={bodyEditor}
									attachments={{
										files: attachments,
										setFiles: setAttachments,
									}}
								/>
							</div>
						</form>
					</FormProvider>

					<div
						className={cx(
							"flex justify-between gap-3",
							isBrandWithBudget && "justify-end",
						)}
					>
						<Button
							className={cx("", isBrandWithBudget && "hidden")}
							variant="outline"
							size="large"
							onClick={clearDefaultTemplate}
						>
							<span className="flex items-center gap-1">
								<ArrowLeft /> Back to Templates
							</span>
						</Button>
						<Button
							variant="primary"
							size="large"
							isLoading={sendPitch.isLoading}
							onClick={form.handleSubmit((data) => onPitch(data, false))}
							disabled={!canSend}
						>
							<div className="flex items-center justify-center gap-2">
								Send Pitch <Pitch />
							</div>
						</Button>
					</div>
				</div>
			</ModalBody>
		</>
	);
};
