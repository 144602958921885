import { PLANS, SubscriptionPlan } from "@withjuly/fabric";
import { ModalBody, StatusIndicator, Text } from "@withjuly/solis";
import { useState } from "react";
import { UpgradeModal } from "~/components/Payment/UpgradeModal";
import { trpc } from "~/components/Utility/trpc";

interface ChooseStrategyProps {
	setStrategy: (strategy: "template" | "ai") => void;
}

export const ChooseStrategy: React.FC<ChooseStrategyProps> = ({
	setStrategy,
}) => {
	const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);

	const { data: billing } = trpc.billing.get.useQuery();
	const plan = billing ? PLANS[billing.plan as SubscriptionPlan] : undefined;
	const hasAiAccess = (plan?.order ?? 0) >= PLANS.trending.order;

	return (
		<>
			<ModalBody className="flex gap-3 p-3">
				<button
					className="relative flex h-[200px] w-full cursor-pointer flex-col items-center justify-center gap-3 rounded-xl border border-gray-600 bg-gray-600 transition-all hover:border-blue-400 hover:bg-blue-700"
					onClick={() => setStrategy("template")}
				>
					<p className="text-3xl">📝</p>
					<Text variant="bold/lg">Pick Template</Text>
				</button>

				<button
					className="relative flex h-[200px] w-full cursor-pointer flex-col items-center justify-center gap-3 rounded-xl border border-gray-600 bg-gray-600 transition-all hover:border-blue-400 hover:bg-blue-700"
					onClick={() => {
						if (hasAiAccess) {
							setStrategy("ai");
						} else {
							setIsUpgradeOpen(true);
						}
					}}
				>
					<div className="absolute right-4 top-4">
						<StatusIndicator
							label="New"
							fill={false}
							status="brand"
							size="small"
						/>
					</div>
					<p className="text-3xl">✨</p>
					<Text variant="bold/lg">Use AI</Text>
				</button>
			</ModalBody>
			<UpgradeModal
				isOpen={isUpgradeOpen}
				setIsOpen={setIsUpgradeOpen}
				feature="ai pitch"
			/>
		</>
	);
};
