import {
	Brand,
	ConnectableAccountProvider,
	DefaultTemplate,
} from "@withjuly/fabric";
import { Loader, Modal, modalSizes } from "@withjuly/solis";
import { useState } from "react";
import { ChooseTemplatePane } from "./panes/ChooseTemplatePane";
import { ConnectGoogleContent } from "./panes/ConnectGoogleContent";
import { EditPitchPane } from "./panes/EditPitchPane";
import { ChooseStrategy } from "./panes/ChooseStrategy";
import { AIPitch } from "./panes/AIPitch";
import { useCreator } from "~/utils/context/creator";
import { trpc } from "~/components/Utility/trpc";

export interface PitchModalProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	brands: Brand[];
	onBoardStep?: { step: string; uuid: string } | undefined;
	setOnBoardStep?: (step: { step: string; uuid: string }) => void;
	showStrategyOptions?: boolean;
	isBrandWithBudget?: boolean;
	isBrandBook?: boolean;
}

export const PitchModal: React.FC<PitchModalProps> = ({
	isOpen,
	setIsOpen,
	brands,
	onBoardStep,
	setOnBoardStep,
	showStrategyOptions = true,
	isBrandWithBudget = false,
	isBrandBook = false,
}) => {
	const [defaultTemplate, setDefaultTemplate] = useState<DefaultTemplate>();
	const { isLoading: _isTemplateLoading } = trpc.template.getDefault.useQuery(
		undefined,
		{
			enabled: isBrandWithBudget === true,
			onSuccess: (data) => {
				if (isBrandWithBudget) {
					const firstDefaultTemplate = data.sections[0]?.templates?.[0];
					setDefaultTemplate(firstDefaultTemplate);
				}
			},
		},
	);
	const [strategy, setStrategy] = useState<"template" | "ai" | undefined>(
		() => {
			if (showStrategyOptions) {
				return undefined;
			} else {
				return "template";
			}
		},
	);
	const { creatorProfile } = useCreator();
	const [isGmailConnected, setIsGmailConnected] = useState(() =>
		creatorProfile?.connectedAccounts.some(
			(a) => a.provider === ConnectableAccountProvider.GOOGLE,
		),
	);

	const setOpen = (open: boolean) => {
		if (!open) {
			// Wait for modal to close before unmounting to prevent pop in
			setTimeout(() => {
				setDefaultTemplate(undefined);
				setStrategy(() => {
					if (brands.length === 1) {
						return undefined;
					} else {
						return "template";
					}
				});
			}, 300);
		}

		setIsOpen(open);
	};

	if (!isGmailConnected) {
		return (
			<Modal
				isOpen={isOpen}
				setIsOpen={setOpen}
				size={modalSizes[480]}
				content={
					<ConnectGoogleContent
						setIsOpen={(isOpen) => setIsOpen(isOpen)}
						onConnected={() => setIsGmailConnected(() => true)}
						companyName={
							brands.length === 1 ? brands[0]?.company.name ?? "" : undefined
						}
					/>
				}
			/>
		);
	}

	if (strategy === undefined) {
		return (
			<Modal
				isOpen={isOpen}
				setIsOpen={setOpen}
				size={modalSizes[480]}
				content={<ChooseStrategy setStrategy={setStrategy} />}
			/>
		);
	} else if (strategy === "ai") {
		return (
			<Modal
				isOpen={isOpen}
				setIsOpen={setOpen}
				size={modalSizes[800]}
				content={
					<AIPitch
						setStrategy={setStrategy}
						setIsOpen={setOpen}
						brands={brands}
						onBoardStep={onBoardStep}
						setOnBoardStep={setOnBoardStep}
						isBrandBook={isBrandBook}
					/>
				}
			/>
		);
	} else if (strategy === "template") {
		if (defaultTemplate || isBrandWithBudget) {
			return (
				<Modal
					isOpen={isOpen}
					setIsOpen={setOpen}
					size={modalSizes[800]}
					content={
						!defaultTemplate ? (
							<div className="flex h-[650px] items-center justify-center">
								<Loader />
							</div>
						) : (
							<EditPitchPane
								setIsOpen={setOpen}
								brands={brands}
								template={defaultTemplate}
								clearDefaultTemplate={() => setDefaultTemplate(undefined)}
								onBoardStep={onBoardStep}
								setOnBoardStep={setOnBoardStep}
								isBrandWithBudget={isBrandWithBudget}
								isBrandBook={isBrandBook}
							/>
						)
					}
				/>
			);
		} else {
			return (
				<Modal
					isOpen={isOpen}
					setIsOpen={setOpen}
					size={modalSizes[800]}
					content={
						<ChooseTemplatePane
							setDefaultTemplate={setDefaultTemplate}
							setIsOpen={setOpen}
						/>
					}
				/>
			);
		}
	}

	return null;
};
