import { Brand } from "@withjuly/fabric";
import { CheckBadge } from "@withjuly/julycons";
import { ProgressCircle, theme } from "@withjuly/solis";

interface BrandInfoProps {
	brand: Brand;
}

export const BrandInfo: React.FC<BrandInfoProps> = ({ brand }) => {
	return (
		<div className="flex w-full flex-col items-start gap-3">
			<>
				{brand.match?.percent ? (
					<div className="flex items-center gap-2">
						<ProgressCircle
							progress={brand.match.percent * 100}
							radius={6}
							stroke={2}
							color={theme.color.white[100]}
							trackColor={theme.color.gray[900]}
						/>
						<p className="text-gray-100">
							{Math.round(brand.match.percent * 100)}% Match
						</p>
					</div>
				) : null}
				<div className="flex items-center gap-2">
					<CheckBadge />
					<p className="text-gray-100">Verified Partnership Email</p>
				</div>
			</>
		</div>
	);
};
