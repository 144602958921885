import { CompanyImage } from "./CompanyImage";
import {
	ModalHeader,
	ModalTitle,
	ModalBody,
	Button,
	Text,
} from "@withjuly/solis";
import { openCompanySite } from "~/utils/match";
import { MediaType } from "@withjuly/fabric";

export interface PostPreview {
	imageUrl: string;
	username?: string;
	caption?: string;
	mediaType?: MediaType;
	postUrl?: string;
}

export interface PostPreviewModalProps {
	onClose: () => void;
	post: PostPreview;
}

export const PostPreviewModal: React.FC<PostPreviewModalProps> = ({
	onClose,
	post,
}) => {
	return (
		<>
			<ModalHeader>
				<ModalTitle>
					{post.username ? `@${post.username}` : "Post Preview"}
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<div className="flex flex-col gap-6">
					<div className="flex flex-col gap-4">
						{post.mediaType ? (
							<CompanyImage
								post={{ mediaUrl: post.imageUrl, mediaType: post.mediaType }}
								alt={`${post.username}'s post`}
								className="aspect-square h-auto rounded-lg"
							/>
						) : (
							<img
								src={post.imageUrl}
								alt={`${post.username}'s post`}
								className="aspect-square h-auto rounded-lg object-cover"
							/>
						)}
						{post.caption && (
							<Text className="w-full whitespace-pre-wrap">{post.caption}</Text>
						)}
					</div>
					<div className="flex gap-3">
						<Button variant="outline" onClick={onClose} full>
							Close
						</Button>
						{post?.postUrl && (
							<Button
								variant="primary"
								onClick={() => openCompanySite(post?.postUrl)}
								full
							>
								View Post
							</Button>
						)}
					</div>
				</div>
			</ModalBody>
		</>
	);
};
