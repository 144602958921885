import { AnimatePresence, motion } from "framer-motion";
import React from "react";

export const SlideFade = ({
	offsetX = 16,
	children,
	className,
}: {
	offsetX?: number;
	children: React.ReactNode;
	className?: string;
}) => {
	return (
		<AnimatePresence>
			<motion.div
				initial={{ opacity: 0, translateX: offsetX }}
				animate={{ opacity: 1, translateX: 0 }}
				exit={{ opacity: 0, translateX: offsetX }}
				transition={{ duration: 0.15, ease: "easeOut" }}
				className={className}
			>
				{children}
			</motion.div>
		</AnimatePresence>
	);
};
