import { Brand } from "@withjuly/fabric";
import { AiSparkle, Check, HeartFull, Pitch } from "@withjuly/julycons";
import {
	ProgressCircle,
	Tooltip,
	TooltipContent,
	TooltipTrigger,
	theme,
} from "@withjuly/solis";
import { AddToQueue } from "./AddToQueue";

interface BrandFooterProps {
	brand: Brand;
	isWishList?: boolean;
	onPitchWithoutPropagation: (e: React.MouseEvent<HTMLElement>) => void;
	lastPitched?: Date;
}

export const BrandFooter: React.FC<BrandFooterProps> = ({
	brand,
	isWishList,
	onPitchWithoutPropagation,
	lastPitched,
}) => {
	const getMatchInfo = () => {
		if (isWishList) {
			return (
				<>
					<HeartFull /> <p className="text-bold-lg">From your wishlist</p>
				</>
			);
		} else if (brand.match?.becauseYouRequested) {
			return (
				<>
					<Tooltip>
						<TooltipTrigger>
							<span>
								<AiSparkle />
							</span>
						</TooltipTrigger>
						<TooltipContent side="bottom">
							<p className="text-body-sm text-white-100">
								Similar to {brand.match.becauseYouRequested?.companyName}
							</p>
						</TooltipContent>
					</Tooltip>
					<p className="text-bold-lg max-w-[16ch] truncate">Recommended</p>
				</>
			);
		} else if (brand.match?.percent) {
			return (
				<>
					<ProgressCircle
						progress={brand.match.percent * 100}
						radius={6}
						stroke={2}
						color={theme.color.white[100]}
						trackColor={theme.color.gray[900]}
					/>
					<p className="text-body-lg text-gray-100">
						{Math.round(brand.match.percent * 100)}% Match
					</p>
				</>
			);
		} else if (brand.brandWithBudget) {
			return (
				<div className="shadow-brand-glow flex items-center justify-center rounded-full border border-blue-500 bg-blue-800 px-2 py-[3px]">
					<p className="text-blue-500">💰 July Partner</p>
				</div>
			);
		} else if (brand.isVerifiedEmail) {
			return (
				<div className="flex items-center justify-center gap-2">
					<Check />
					<p className="text-gray-100">Verified Contact</p>
				</div>
			);
		}
	};

	const hasPitchedRecently =
		(lastPitched?.getTime() ?? 0) > Date.now() - 1000 * 60 * 60 * 24;

	return (
		<div className="flex w-full items-center justify-between">
			<div className="flex items-center gap-2">{getMatchInfo()}</div>
			<div className="flex items-center gap-3">
				{brand.match ? <AddToQueue brand={brand} /> : null}

				{hasPitchedRecently ? (
					<button
						className="rounded-full bg-gray-500 px-4 py-3 transition-all duration-150 ease-in-out hover:bg-gray-400 focus-visible:ring-4"
						onClick={onPitchWithoutPropagation}
					>
						<p className="text-bold-lg flex items-center gap-1 text-gray-200">
							Pitched
						</p>
					</button>
				) : (
					<button
						className="rounded-full bg-blue-500 px-4 py-3 transition-all duration-150 ease-in-out hover:bg-blue-400 focus-visible:ring-4"
						onClick={onPitchWithoutPropagation}
					>
						<p className="text-bold-lg flex items-center gap-1 text-gray-900">
							Pitch <Pitch />
						</p>
					</button>
				)}
			</div>
		</div>
	);
};
