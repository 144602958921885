import { Brand } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { Button } from "@withjuly/solis";
import { useState } from "react";
import { trpc } from "~/components/Utility/trpc";
import { PostPreview } from "../PostPreviewModal";
import { AwwPost } from "./AwwPost";
import { UpgradeModal } from "~/components/Payment/UpgradeModal";

interface MatchDrawerAwwProps {
	brand: Brand;
	setCurrentPost: (post?: PostPreview) => void;
	setIsPostModalOpen: (open: boolean) => void;
}
export const MatchDrawerAww: React.FC<MatchDrawerAwwProps> = ({
	brand,
	setCurrentPost,
	setIsPostModalOpen,
}) => {
	const { data: billing } = trpc.billing.get.useQuery();
	const isFreePlan = billing?.plan === "free";
	const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

	if (!brand.campaign?.alsoWorkingWith?.length) return null;
	return (
		<>
			<div className="flex flex-col gap-4 p-8">
				<div className="flex items-center justify-between">
					<p className="text-white-100">Also Working With</p>
				</div>
				<div className="relative">
					<div
						className={cx("flex flex-col gap-6", isFreePlan && "opacity-50")}
					>
						{brand.campaign?.alsoWorkingWith.map((entry) => (
							<AwwPost
								key={entry.imageUrl}
								entry={entry}
								onClick={() => {
									if (!entry.imageUrl) {
										return;
									}

									setCurrentPost({
										imageUrl: entry.imageUrl,
										username: entry.username,
										caption: entry.caption,
										postUrl: entry.postUrl,
									});
									setIsPostModalOpen(true);
								}}
							/>
						))}
					</div>
					{isFreePlan && (
						<div
							className="absolute left-[-1rem] top-[-1rem] flex h-[calc(100%+2rem)] w-[calc(100%+2rem)] items-center justify-center backdrop-blur-sm"
							onClick={() => {
								setIsUpgradeModalOpen(true);
							}}
						>
							<div>
								<Button size="large">Upgrade to unlock</Button>
							</div>
						</div>
					)}
				</div>
			</div>

			<UpgradeModal
				isOpen={isUpgradeModalOpen}
				setIsOpen={setIsUpgradeModalOpen}
				feature="also working with"
			/>
		</>
	);
};
