import { trpc } from "~/components/Utility/trpc";

export const useDeleteMatches = () => {
	const utils = trpc.useContext();

	return trpc.match.deleteMany.useMutation({
		onMutate: async ({ uuids }) => {
			// Cancel any outgoing refetches
			// (so they don't overwrite our optimistic update)
			await utils.match.paginated.cancel();

			// Snapshot the previous value
			const previousMatches = utils.match.paginated.getInfiniteData();

			// Optimistically update to the new value
			utils.match.paginated.setInfiniteData({ limit: 5 }, (previousMatches) => {
				if (!previousMatches) {
					return previousMatches;
				}

				return {
					pages: previousMatches.pages.map((page) => {
						return {
							...page,
							items: page.items.filter(
								(brand) =>
									!uuids
										.map((uuids) => uuids.data)
										.includes(brand.match?.uuid ?? ""),
							),
						};
					}),
					pageParams: previousMatches.pageParams,
				};
			});

			// Return a context object with the snapshotted value
			return { previousMatches };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (_err, _new, context) => {
			utils.match.paginated.setInfiniteData(
				{ limit: 5 },
				context?.previousMatches,
			);
		},
		// Always refetch after error or success:
		onSettled: () => {
			utils.match.paginated.invalidate();
			utils.match.wishlist.invalidate();
		},
	});
};
