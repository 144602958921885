import React, { ReactNode, useContext } from "react";
import { RouterOutput, trpc } from "~/components/Utility/trpc";

export const BillingContext = React.createContext<
	RouterOutput["billing"]["get"] | undefined
>(undefined);

const useProvideBilling = () => {
	const { data: billing } = trpc.billing.get.useQuery(undefined, {
		refetchOnWindowFocus: false,
	});

	return billing;
};

export const BillingProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const value = useProvideBilling();
	return (
		<BillingContext.Provider value={value}>{children}</BillingContext.Provider>
	);
};

export const useBilling = () => useContext(BillingContext);
