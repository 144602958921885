import { Brand, formatOxfordList } from "@withjuly/fabric";
import { Close, ExternalLink } from "@withjuly/julycons";
import { BrandInfo } from "../BrandCard/BrandInfo";

interface MatchDrawerHeaderProps {
	brand: Brand;
	setIsOpen: (isOpen: boolean) => void;
}

export const MatchDrawerHeader: React.FC<MatchDrawerHeaderProps> = ({
	brand,
	setIsOpen,
}) => {
	const companyUrl = brand.company.url ? new URL(brand.company.url) : null;

	return (
		<div className="px-8">
			<div className="items-star flex flex-col border-b border-gray-300 py-8">
				<div className="flex w-full justify-between pb-8">
					<div className="flex flex-row items-center justify-center gap-6">
						<div className="flex h-20 w-20 items-center justify-center rounded-full bg-gray-900">
							{brand.company.profilePicUrl ? (
								<img
									src={brand.company.profilePicUrl}
									alt=""
									className="min-h-[80px] min-w-[80px] rounded-full"
								/>
							) : (
								<p className="text-h4">{brand.company.name[0] ?? ""}</p>
							)}
						</div>
						<div className="flex flex-col gap-[2px]">
							<p className="text-h4 text-white-100 font-agrandir">
								{brand.company.name}
							</p>
							<p className="text-white-100">
								{formatOxfordList(brand.campaign?.industries.split(",") ?? [])}{" "}
								Brand
							</p>
							{companyUrl ? (
								<a
									href={companyUrl.toString()}
									target="_blank"
									className="flex w-fit items-center gap-2 text-gray-200 transition-colors hover:text-blue-100"
								>
									<p>{companyUrl.host}</p>
									<ExternalLink />
								</a>
							) : null}
						</div>
					</div>
					<button
						className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg transition-all duration-150 ease-in-out hover:bg-gray-600"
						onClick={() => setIsOpen(false)}
					>
						<Close />
					</button>
				</div>
				<div className="flex flex-col gap-6">
					<div className="w-full">
						<BrandInfo brand={brand} />
					</div>
					<p className="text-gray-100">{brand.company.description}</p>
				</div>
			</div>
		</div>
	);
};
