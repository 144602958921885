import { Brand, MediaType } from "@withjuly/fabric";
import { useCallback, useState } from "react";
import { CompanyImage } from "../CompanyImage";
import { BrandHeader } from "./BrandHeader";
import { BrandFooter } from "./BrandFooter";
import { MatchDrawer } from "../MatchDrawer";
import { PitchModal } from "../../Pitch/PitchModal/PitchModal";
import { Close, Pitch } from "@withjuly/julycons";
import TestPitch from "~/assets/TestPitch.svg";
import { useDeleteMatches } from "~/utils/api/query/matches";
import { cx } from "@withjuly/frontend-common";
import { UpgradeModal } from "~/components/Payment/UpgradeModal";

export interface BrandCardProps {
	brand: Brand;
	isWishlist?: boolean;
	setOnBoardStep?: (step: { step: string; uuid: string }) => void;
	onBoardStep?: { step: string; uuid: string } | undefined;
	isMock?: boolean;
	isBrandBook?: boolean;
	canPitch?: boolean;
	lastPitched?: Date;
}

export const BrandCard: React.FC<BrandCardProps> = ({
	brand,
	isWishlist,
	setOnBoardStep,
	onBoardStep,
	isMock,
	isBrandBook,
	canPitch = true,
	lastPitched,
}) => {
	const [isPitchOpen, setIsPitchOpen] = useState(false);
	const [currentMatch, setCurrentMatch] = useState<Brand>();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isUpgradeOpen, setIsUpgradeOpen] = useState(() => false);
	const deleteMatches = useDeleteMatches();

	const isOnBoardPitch =
		brand.company.url === "https://www.withjuly.com" && !isBrandBook;

	const backgroundPost = brand.company.posts?.find(
		(post) => post.mediaType !== MediaType.VIDEO,
	);

	const onClick = useCallback(
		(brand: Brand) => {
			setCurrentMatch(brand);
			setIsDrawerOpen(true);
		},
		[setCurrentMatch, setIsDrawerOpen],
	);

	const onPitch = useCallback(() => {
		if (!canPitch) {
			setIsUpgradeOpen(() => true);
			return;
		}

		if (isOnBoardPitch && onBoardStep && brand.match) {
			setOnBoardStep?.({ ...onBoardStep, uuid: brand.match.uuid ?? "" });
		}

		setCurrentMatch(brand);
		setIsPitchOpen(true);
	}, [canPitch, isOnBoardPitch, onBoardStep, brand, setOnBoardStep]);

	const onPitchWithoutPropagation = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			if (isMock) {
				return;
			}
			e.stopPropagation();
			onPitch();
		},
		[isMock, onPitch],
	);

	return (
		<>
			{isOnBoardPitch && !isBrandBook ? (
				<div className="shadow-60 flex h-[25rem] flex-col justify-between rounded-xl border border-dashed border-gray-300 bg-gray-800 p-6 sm:h-[30rem]">
					<div className="flex flex-col gap-3">
						<div className="flex w-full items-start justify-between">
							<h4 className="text-h4">Discover Dashboard</h4>
							<button
								className="cursor-pointer"
								onClick={() => {
									if (isMock) {
										return;
									}

									if (brand.match) {
										deleteMatches.mutate({
											uuids: [{ type: "match", data: brand.match.uuid }],
										});
									}
								}}
							>
								<Close />
							</button>
						</div>
						<p>Brands shown here are working with creators similar to you.</p>
					</div>
					<div className="self-end">
						<div className="flex items-center gap-3">
							<div className="absolute -translate-x-20 -translate-y-[52px] transform">
								<img src={TestPitch.src} alt="Send yourself a test pitch" />
							</div>
							<button
								className="z-10 cursor-pointer rounded-full bg-blue-500 px-4 py-3 transition-all duration-150 ease-in-out hover:bg-blue-600 focus-visible:ring-4"
								onClick={(e) => {
									if (isMock) {
										return;
									}
									onPitchWithoutPropagation(e);
								}}
							>
								<p className="text-bold-lg flex cursor-pointer items-center gap-1 text-gray-900">
									Pitch <Pitch />
								</p>
							</button>
						</div>
					</div>
				</div>
			) : (
				<div
					className={cx(
						"shadow-40 group relative flex h-[25rem] cursor-pointer flex-col items-start justify-end overflow-hidden rounded-2xl border border-gray-500 bg-cover text-[#f6f6f6] sm:h-[30rem]",
						brand.brandWithBudget && "border-[5px] border-blue-500",
					)}
					onClick={() => {
						if (isMock) {
							return;
						}

						onClick(brand);
					}}
				>
					<CompanyImage
						alt={`${brand.company.name}'s latest post`}
						post={backgroundPost}
						isBackground
					/>
					<div
						className="hover:bg-white-100 z-10 h-full w-full transition-all duration-200 group-hover:opacity-0"
						style={{
							background:
								"linear-gradient(180deg, rgba(7, 8, 8, 0) 0%, #0E0F10 100%)",
						}}
					/>
					<div className="z-10 flex w-full flex-col justify-between gap-2 bg-gray-800 px-6 pb-6 pt-14">
						<div
							className="absolute flex h-16 w-16 -translate-y-[88px] transform items-center justify-center rounded-full bg-gray-500"
							style={{
								boxShadow: "0px 0px 32px -4px #0E0F10",
							}}
						>
							{brand.company.profilePicUrl ? (
								<img
									src={brand.company.profilePicUrl}
									alt=""
									className="min-h-[64px] min-w-[64px] rounded-full"
								/>
							) : (
								<p className="text-h4">{brand.company.name[0] ?? ""}</p>
							)}
						</div>
						<BrandHeader brand={brand} />
						<BrandFooter
							isWishList={isWishlist}
							brand={brand}
							onPitchWithoutPropagation={onPitchWithoutPropagation}
							lastPitched={lastPitched}
						/>
					</div>
				</div>
			)}
			{currentMatch ? (
				<MatchDrawer
					isOpen={isDrawerOpen}
					setIsOpen={setIsDrawerOpen}
					brand={currentMatch}
					onPitch={onPitch}
					isBrandBook={isBrandBook}
				/>
			) : null}

			{currentMatch !== undefined ? (
				<PitchModal
					onBoardStep={onBoardStep}
					setOnBoardStep={setOnBoardStep}
					isOpen={isPitchOpen}
					setIsOpen={setIsPitchOpen}
					brands={[brand]}
					isBrandBook={true}
				/>
			) : null}
			<UpgradeModal
				isOpen={isUpgradeOpen}
				setIsOpen={setIsUpgradeOpen}
				feature="brand book"
			/>
		</>
	);
};
