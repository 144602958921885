import { Brand } from "@withjuly/fabric";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { CompanyImage } from "../CompanyImage";
import { Button } from "@withjuly/solis";
import { cx } from "@withjuly/frontend-common";

interface MatchDrawerFeedProps {
	brand: Brand;
}

export const MatchDrawerFeed: React.FC<MatchDrawerFeedProps> = ({ brand }) => {
	const [isViewingMore, setIsViewingMore] = useState(false);

	return (
		<div className="px-8">
			<div className="flex flex-col gap-4 border-b border-gray-300 py-8">
				<p className="text-white-100">Instagram Feed</p>
				<div className="grid grid-cols-[repeat(3,1fr)] gap-2">
					{brand.company.posts.length
						? brand.company.posts
								.slice(0, isViewingMore ? 9 : 3)
								.map((post, i) => (
									<a
										key={post.mediaUrl}
										href={post.postUrl}
										target="_blank"
										rel="noreferrer"
									>
										<CompanyImage
											alt={`${brand.company.name} post #${i}`}
											post={post}
											className="aspect-square h-auto cursor-pointer rounded-lg object-cover"
										/>
									</a>
								))
						: Array.from({ length: 3 }).map((_, i) => (
								<div
									key={i}
									className="flex h-[133px] w-full rounded-lg bg-gray-400"
								/>
							))}
				</div>
				{brand.company.posts.length > 3 ? (
					<Button
						variant="outline"
						size="large"
						onClick={() => setIsViewingMore(!isViewingMore)}
					>
						<div className="flex items-center justify-center gap-2">
							{isViewingMore ? "Show Less" : "Show More"}
							<div
								className={cx(
									isViewingMore && "rotate-180",
									"transition-transform duration-200",
								)}
							>
								<FaChevronDown />
							</div>
						</div>
					</Button>
				) : null}
			</div>
		</div>
	);
};
