import { Brand } from "@withjuly/fabric";
import { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { formatOxfordCompanyList } from "@withjuly/fabric";
import {
	useToast,
	ModalHeader,
	ModalTitle,
	ModalBody,
	Button,
	Text,
	ModalDescription,
	StatusIndicator,
	Heading,
	AiLoader,
} from "@withjuly/solis";
import posthog from "posthog-js";
import { applyApiValidationErrors } from "~/utils/form";
import { useApiErrorHandler } from "~/utils/api";
import { RichTextInput } from "~/components/Input";
import { FormProvider } from "react-hook-form";
import { ArrowLeft, Pitch } from "@withjuly/julycons";
import { trpc } from "~/components/Utility/trpc";
import { useZodForm } from "~/utils/hooks/zod-form";
import { RichSubjectInput } from "~/components/Input/RichText/RichSubjectInput";
import {
	convertSubjectToHtml,
	useBodyEditor,
	useSubjectEditor,
} from "~/components/Templates/utils";
import { AttachedFile } from "~/utils/context/rich-text";
import { HiRefresh } from "react-icons/hi";

const PitchModalInputSchema = z.object({
	subject: z.string().min(1, "Subject is required"),
	body: z.string().min(1, "Body is required"),
	attachments: z.array(z.string().uuid()),
	useOnPlatformInbounds: z.boolean(),
	templateUuid: z.string(),
});
export type PitchModalInput = z.infer<typeof PitchModalInputSchema>;

interface AIPitchProps {
	setIsOpen: (isOpen: boolean) => void;
	setStrategy: (strategy?: "template" | "ai") => void;
	brands: Brand[];
	onBoardStep?: { step: string; uuid: string } | undefined;
	setOnBoardStep?: (step: { step: string; uuid: string }) => void;
	isBrandBook?: boolean;
}

export const AIPitch: React.FC<AIPitchProps> = ({
	setIsOpen,
	setStrategy,
	brands,
	onBoardStep,
	setOnBoardStep,
	isBrandBook = false,
}) => {
	const [attachments, setAttachments] = useState<AttachedFile[]>([]);
	const [edited, setEdited] = useState(false);

	const { handleApiError } = useApiErrorHandler();
	const utils = trpc.useContext();
	const sendPitch = trpc.dealFlow.pitch.useMutation({
		onSuccess: (_, variables) => {
			if (!variables.isTestPitch) {
				utils.user.invalidate();
				utils.match.paginated.invalidate();
				utils.match.wishlist.invalidate();
				utils.dealFlow.getQueuedPitches.invalidate();
				utils.match.queueable.invalidate();
			}
		},
	});
	const generatePitch = trpc.dealFlow.generatePitch.useMutation({
		onSuccess: () => {
			posthog.capture("AI Generate Pitch");
			setEdited(false);
		},
	});

	const { toast } = useToast();

	const form = useZodForm({
		schema: PitchModalInputSchema,
		values: {
			subject: "",
			body: "",
			attachments: [],
			useOnPlatformInbounds: false,
			templateUuid: "",
		},
	});

	const subjectEditor = useSubjectEditor("", {
		onUpdate: (value) => {
			form.setValue("subject", value);
			setEdited(true);
		},
	});
	const bodyEditor = useBodyEditor("", {
		onUpdate: (value) => {
			form.setValue("body", value);
			setEdited(true);
		},
	});

	const onPitch = async (data: PitchModalInput, isTestPitch: boolean) => {
		const pitchData = {
			// We are filtering out any non-string entries so we can confidently type cast
			campaignUuids: brands
				.filter((b) => typeof b.campaign?.uuid === "string")
				.map((b) => b.campaign?.uuid) as string[],
			subject: subjectEditor?.getText() ?? "",
			htmlContent: bodyEditor?.getHTML() ?? "",
			textContent: bodyEditor?.getText() ?? "",
			attachmentUuids: attachments.map((a) => a.uuid),
			useOnPlatformInbounds: data.useOnPlatformInbounds,
		};

		if (
			onBoardStep &&
			setOnBoardStep &&
			brands.find((brand) => brand.match?.uuid === onBoardStep.uuid)
		) {
			setOnBoardStep({ ...onBoardStep, step: "dealflow" });
		}

		sendPitch.mutate(
			{ ...pitchData, isTestPitch },
			{
				onSuccess: () => {
					toast({
						title: "Email Sent",
						status: "success",
						description: `We've sent your message to ${formatOxfordCompanyList(
							brands.map((b) => b.company.name),
						)}!`,
					});

					posthog.capture("creator pitch", {
						brandCount: pitchData.campaignUuids.length,
						attachmentCount: pitchData.attachmentUuids.length,
						useOnPlatformInbounds: pitchData.useOnPlatformInbounds,
						usedAi: true,
						editedAfterAi: edited,
						isBrandBook,
					});
					setIsOpen(false);
				},
				onError: (error) => {
					applyApiValidationErrors(error, form.setError);
					handleApiError(error);
				},
			},
		);
	};

	useEffect(() => {
		if (generatePitch.isLoading || !bodyEditor || !subjectEditor) {
			return;
		}

		generatePitch.mutate(brands[0]?.company.uuid ?? "", {
			onSuccess: (data) => {
				subjectEditor?.commands.setContent(convertSubjectToHtml(data.subject));
				bodyEditor?.commands.setContent(
					convertSubjectToHtml(data.body).replaceAll("\n", "<br>"),
				);

				// Set values for validation. Actual values will be pulled from editor, so it's ok if formatting is off.
				form.setValue("subject", data.subject);
				form.setValue("body", data.body);
			},
			onError: () => {
				toast({
					title: "Error",
					description: "An error has occurred, please try again",
					status: "danger",
				});
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bodyEditor, subjectEditor]);

	const subject = form.watch("subject");
	const canSend = useMemo(() => {
		return (bodyEditor?.getText().length ?? 0) > 0 && subject.length > 0;
	}, [subject, bodyEditor]);

	if (!bodyEditor) return null;

	return (
		<>
			<ModalHeader>
				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-3">
						<ModalTitle className="flex items-center gap-3" asChild>
							<span style={{ fontFamily: "system-ui" }}>✨</span> Pitch with AI
							<StatusIndicator
								label="Beta"
								status="brand"
								size="small"
								fill={false}
							/>
						</ModalTitle>
					</div>
					<ModalDescription asChild>
						<Text className="text-gray-100">
							This pitch will be sent to{" "}
							{formatOxfordCompanyList(
								brands.map((b) => {
									if (
										b.company.url &&
										b.company.url === "https://www.withjuly.com"
									) {
										return "your email";
									}

									return b.company.name;
								}),
							)}
							.
						</Text>
					</ModalDescription>
				</div>
			</ModalHeader>

			<ModalBody>
				<div className="flex flex-col gap-6">
					<FormProvider {...form}>
						<form
							id="pitch-form"
							onSubmit={form.handleSubmit((data) => onPitch(data, false))}
						>
							<div className="flex flex-col gap-3">
								{generatePitch.isLoading ? null : (
									<RichSubjectInput editor={subjectEditor} />
								)}

								{generatePitch.isLoading ? (
									<div className="flex min-h-[24rem] w-full flex-col items-center  justify-center gap-7 rounded-lg border border-gray-200 bg-gray-600">
										<div className="flex flex-col items-center justify-center gap-1">
											<Heading variant="h5">July</Heading>
											<Text variant="body/sm" className="text-gray-200">
												Writing your message...
											</Text>
										</div>
										<AiLoader />
									</div>
								) : (
									<RichTextInput
										name="body"
										editor={bodyEditor}
										attachments={{
											files: attachments,
											setFiles: setAttachments,
										}}
									/>
								)}
							</div>
						</form>
					</FormProvider>

					<div className="flex justify-between gap-3">
						{generatePitch.isLoading ? (
							<div></div>
						) : (
							<Button
								variant="outline"
								size="large"
								onClick={() =>
									generatePitch.mutate(brands[0]?.company?.uuid ?? "", {
										onSuccess: (data) => {
											subjectEditor?.commands.setContent(
												convertSubjectToHtml(data.subject),
											);
											bodyEditor?.commands.setContent(
												convertSubjectToHtml(data.body).replaceAll(
													"\n",
													"<br>",
												),
											);

											// Set values for validation. Actual values will be pulled from editor, so it's ok if formatting is off.
											form.setValue("subject", data.subject);
											form.setValue("body", data.body);
										},
										onError: () => {
											toast({
												title: "Error",
												description: "An error has occurred, please try again",
												status: "danger",
											});
										},
									})
								}
								isLoading={generatePitch.isLoading}
							>
								<span className="flex items-center gap-1">
									<HiRefresh /> Try again
								</span>
							</Button>
						)}

						<div className="flex gap-3">
							<Button
								variant="outline"
								size="large"
								onClick={() => setStrategy(undefined)}
							>
								<span className="flex items-center gap-1">
									<ArrowLeft /> Back
								</span>
							</Button>
							<Button
								variant="primary"
								size="large"
								isLoading={sendPitch.isLoading}
								onClick={form.handleSubmit((data) => onPitch(data, false))}
								disabled={!canSend}
							>
								<div className="flex items-center justify-center gap-2">
									Send Pitch <Pitch />
								</div>
							</Button>
						</div>
					</div>
				</div>
			</ModalBody>
		</>
	);
};
